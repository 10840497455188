<template>
  <iframe :src="webUrl+'/admin/crm/factory.list'"></iframe>
</template>

<script>


export default {
  name: "list",
  setup(){
    return {
      "webUrl" : process.env.VUE_APP_DAILIAO_WEB_URL
    }
  }
}
</script>

<style scoped>

</style>